<template>
  <div>
    <APIGatewayEdit
      :item="currentItem"
      :filiais="filiais"
      :update="updateItem"      
      :close="closeEdit"
      :descSingular="descSingular"
    ></APIGatewayEdit>
    <Loading :isActive="loading"></Loading>
    <CTabs variant="tabs" class="nav-tabs-boxed">
      <CTab :title=" descPlural ">
        <APIGatewayTable :items="items" :openEdit="openEdit" :openCreate="openCreate" :deleteConfirm="deleteConfirm"/>
      </CTab>
    </CTabs>
  </div>
</template>
<script>
import clone from 'just-clone';
import { get, post, put, del } from '@/helpers/apiRequest';
import APIGatewayEdit from './components/APIGatewayEdit';
import APIGatewayTable from './components/APIGatewayTable';
import _filter      from 'lodash/filter';

const ItemCatalogo = {
  id          : null,  
  descricao   : null,
  host        : null,  
}

export default {
  name: 'APIGateway',
  components: {
    APIGatewayEdit,
    APIGatewayTable,
  },
  data() {
    return {
      descPlural   : '',
      descSingular : '',
      loading      : false,
      items        : [],
      modulos      : [],
      currentItem  : {},
      filiais      : []
    };
  },
  methods: {
    loadDescricaoModulo() {
      const { modulos } = JSON.parse(localStorage.getItem('app'));
      const { descPlural, descSingular } = modulos.find(
        (m) => m.nome === 'ADMIN_DOMINIO'
      );
      this.descPlural = descPlural;
      this.descSingular = descSingular;
    },
    loadCatalogos() {
      this.loading = true;
      get('/admin/site-catalogos')
        .then((json) => {
          this.items       = json;
          this.loading     = false;
        })
        .catch(() => (this.loading = false));
    },
    openCreate() {
      this.currentItem = clone(ItemCatalogo);
      this.$modal.show('catalogoEdit');
    },
    closeCreate() {
      this.$modal.hide('catalogoEdit');
    },
    openEdit(item) {
      item.arquivo     = null;
      this.currentItem = clone(item);
      this.loading     = false;
      this.$modal.show('catalogoEdit');
    },
    closeEdit() {
      this.$modal.hide('catalogoEdit');
    },
    doRequest() {      
      return this.currentItem.id ?
      post(`/admin/site-catalogos/${this.currentItem.id}`, this.currentItem)
      :
      post('/admin/site-catalogos', this.currentItem);
    },
    updateItem() {
      this.doRequest()
        .then((json) => {
          this.closeEdit();
          this.loadCatalogos();
          this.$swal({
            title: 'Salvo com sucesso',
            text: json.message,
            showCancelButton: false,
            cancelButtonText: 'Fechar',
            showConfirmButton: true,
            icon: 'success',
          });          
        })
        .catch((error) => {
          this.$swal({
            title: 'Falha ao Salvar',
            text: error.message,
            showCancelButton: true,
            cancelButtonText: 'Fechar',
            showConfirmButton: false,
            icon: 'error',
          });
        });
    },
    deleteConfirm(id) {
      this.$swal({
        title: 'Confirma Remover ?',
        text: 'Essa ação não poderá ser desfeita',
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
      })
        .then((res) => {
          if (res.value) {
            this.loading = true;
            del(`/admin/site-catalogos/${id}`)
              .then((json) => {
                this.loading = false;
                this.$swal({
                  title: 'Removido com sucesso',
                  icon: 'success',
                });
                this.loadCatalogos();
              })
              .catch((err) => {
                this.loading = false;
                this.$swal({
                  title: 'Falha ao remover',
                  icon: 'error',
                });
              });
          }
        })
        .catch(() => {});
    },
    loadFiliais() {
      this.loading = true;
      get('/admin/site-filiais')
        .then((json) => {
          this.filiais = json;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
  },
  beforeMount() {
    this.loading = true;
    this.loadCatalogos();
    this.loadDescricaoModulo();
    this.loadFiliais();
  },
};
</script>
